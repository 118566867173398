import React from "react";
import { Box, Flex, Spacer, Image, Heading } from "@chakra-ui/react";

const HeaderNew = () => {
  return (
    <Box
      backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_1022824408.jpg')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      w="100%"
      h={{ base: "350px", md: "600px" }}
      py="150px"
    >
      <Flex
        maxW="container.lg"
        mx="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Heading
          as="h1"
          color="white"
          fontSize={["4xl", "5xl", "8xl"]}
          textAlign="center"
          mb={4}
          fontFamily="'Montserrat', sans-serif"
        >
          Travel Time
        </Heading>
        <Heading
          as="h2"
          color="white"
          fontSize={["xl", "2xl", "4xl"]}
          textAlign="center"
          fontFamily="'Montserrat', sans-serif"
        >
          Principalul consolidator de bilete de avion
        </Heading>
      </Flex>
    </Box>
  );
};

export default HeaderNew;
