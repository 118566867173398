// import React from "react";
// import {
//   MDBFooter,
//   MDBContainer,
//   MDBCol,
//   MDBRow,
//   MDBIcon,
//   MDBBtn,
// } from "mdb-react-ui-kit";
// import { Link } from "react-router-dom";
// import NTPLogo from "ntp-logo-react";

// import "./footer.css";

// const Footer = () => {
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };
//   return (
//     <MDBFooter bgColor="light" className="text-center text-lg-start text-muted">
//       <section className="">
//         <MDBContainer className="text-center text-md-start mt-5">
//           <MDBRow className="mt-3">
//             <MDBCol
//               md="3"
//               lg="3"
//               xl="3"
//               className="mx-auto mb-4 justify-content-center"
//             >
//               <h2 className="text-uppercase fw-bold mb-4">
//                 <MDBIcon icon="gem" className="me-3" />
//                 TRAVEL TIME D&R
//               </h2>
//               <p className="text-start">
//                 Travel Time – o companie de top cu soluții de management al
//                 călătoriilor corporate și B2B – și-a construit reputația oferind
//                 servicii de excelență cu consecvență. Condusă de o filozofie
//                 concentrată pe satisfacția clientului și susținută de tehnologie
//                 inovatoare, Travel Time modelează viitorul managementului
//                 călătoriilor corporate.
//               </p>
//             </MDBCol>

//             <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
//               <h3 className="text-uppercase fw-bold mb-4">Link-uri Utile</h3>
//               {/* <ul>
//                 <Link to={"/vacante"} onClick={() => scrollToTop()}>
//                   Vacante
//                 </Link>
//               </ul> */}
//               {/* <ul>
//                 <Link to={"/corporate"} onClick={() => scrollToTop()}>
//                   Corporate Travel
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/leisure"} onClick={() => scrollToTop()}>
//                   Leisure Travel
//                 </Link>
//               </ul> */}
//               {/* <ul>
//                 <Link to={"/blog"} onClick={() => scrollToTop()}>
//                   Blog
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/europa"} onClick={() => scrollToTop()}>
//                   Europa
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/africa"} onClick={() => scrollToTop()}>
//                   Africa
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/asia"} onClick={() => scrollToTop()}>
//                   Asia
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/romania"} onClick={() => scrollToTop()}>
//                   Romania
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/americadesud"} onClick={() => scrollToTop()}>
//                   America de Sud
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/americadenord"} onClick={() => scrollToTop()}>
//                   America de Nord
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/australiasioceania"} onClick={() => scrollToTop()}>
//                   Australia si Oceania
//                 </Link>
//               </ul> */}
//               {/* <ul>
//                 <Link to={"/revelion"} onClick={() => scrollToTop()}>
//                   Revelion 2024
//                 </Link>
//               </ul> */}
//               <ul>
//                 <Link to={"/agentii-partenere"} onClick={() => scrollToTop()}>
//                   Agentii Partenere
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/conditiidecalatorie"} onClick={() => scrollToTop()}>
//                   Conditii de Calatorie
//                 </Link>
//               </ul>
//               <a
//                 href="https://www.travel-time.ro/images/documente/Comunicat%20lansare%20proiect%20ECOSTART%20312794.pdf"
//                 target="_blank"
//                 rel="noreferrer"
//               >
//                 {" "}
//                 Comunicat de presa Lansare proiect - Ecostart
//               </a>
//               <ul>
//                 <Link
//                   to={"/comunicat-de-presa-proiect-lansare"}
//                   onClick={() => scrollToTop()}
//                 >
//                   Comunicat de Presa Proiect Lansare
//                 </Link>
//               </ul>
//               <ul>
//                 <Link
//                   to={"/comunicat-de-presa-proiect-finalizare"}
//                   onClick={() => scrollToTop()}
//                 >
//                   Comunicat de Presa Proiect Finalizare
//                 </Link>
//               </ul>
//               <ul>
//                 <Link
//                   to={"/comunicat-finalizare-proiect-ID-131118"}
//                   onClick={() => scrollToTop()}
//                 >
//                   Comunicat finalizare proiect -ID 131118
//                 </Link>
//               </ul>
//               <ul>
//                 <Link to={"/contact"} onClick={() => scrollToTop()}>
//                   Contact
//                 </Link>
//               </ul>
//             </MDBCol>

//             <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
//               <h4 className="text-uppercase fw-bold mb-4">Contact</h4>
//               <p>
//                 <MDBIcon icon="home" className="me-2" />
//                 Strada Mircea Vulcanescu, nr 45, Sector 1, Bucuresti
//               </p>
//               <p>
//                 <MDBIcon icon="envelope" className="me-3" />
//                 <strong>
//                   Adresa Generala:
//                   <a href="mailto:office@travel-time.ro">
//                     {" "}
//                     office@travel-time.ro
//                   </a>
//                 </strong>
//               </p>
//               <p>
//                 <MDBIcon icon="envelope" className="me-3" />
//                 <strong>
//                   Departament Ticketing:
//                   <a href="mailto:ticketing@travel-time.ro">
//                     {" "}
//                     ticketing@travel-time.ro
//                   </a>
//                 </strong>
//               </p>
//               <p>
//                 <MDBIcon icon="envelope" className="me-3" />
//                 <strong>
//                   Departament Turism:
//                   <a href="mailto:turism@travel-time.ro">
//                     {" "}
//                     turism@travel-time.ro
//                   </a>
//                 </strong>
//               </p>
//               <p>
//                 <MDBIcon icon="phone" className="me-3" />{" "}
//                 <a href="tel:0371121272">0371 12 12 72</a>
//               </p>
//               <MDBBtn
//                 floating
//                 className="m-1"
//                 style={{ backgroundColor: "#3b5998" }}
//                 href="https://www.facebook.com/traveltimeromania"
//                 role="button"
//               >
//                 <MDBIcon fab icon="facebook-f" />
//               </MDBBtn>

//               <MDBBtn
//                 floating
//                 className="m-1"
//                 style={{ backgroundColor: "#ac2bac" }}
//                 href="https://www.instagram.com/traveltime.ro/"
//                 role="button"
//               >
//                 <MDBIcon fab icon="instagram" />
//               </MDBBtn>
//             </MDBCol>
//             <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
//               <h5 className="text-uppercase fw-bold mb-4">
//                 Date de Identificare
//               </h5>
//               <p>SC TRAVEL TIME D&R SRL</p>
//               <p>
//                 Sediul Social:Adresa : Strada ANTON PANN, Nr. 2, parter, ap.3,
//                 sector 3, Bucuresti
//               </p>
//               <p>CIF: RO17926970</p>
//               <p>Nr. Reg. Com.: J40/15226/2005</p>

//               <MDBRow className="d-flex justify-content-center">
//                 <img
//                   src="../images/iata.png"
//                   alt="Logo 1"
//                   style={{ width: "80px", height: "35px" }}
//                 />
//                 <img
//                   src="../images/anat.png"
//                   alt="Logo 2"
//                   style={{ width: "80px", height: "40px" }}
//                 />
//                 <img
//                   src="../images/logo-travelnation.png"
//                   alt="Logo 3"
//                   style={{ width: "150px", height: "50px" }}
//                 />
//               </MDBRow>
//               <MDBRow className="d-flex justify-content-center">
//                 <NTPLogo color="#74deec" version="orizontal" secret="136590" />
//               </MDBRow>
//               <MDBRow className="d-flex justify-content-center mt-3">
//                 <div className="col-6 col-md-auto text-center">
//                   <a
//                     href="https://anpc.ro/ce-este-sal/"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     <img
//                       src="../images/SOL.png"
//                       alt="Logo 1"
//                       style={{ width: "140px", height: "35px" }}
//                     />
//                   </a>
//                 </div>
//                 <div className="col-6 col-md-auto text-center">
//                   <a
//                     href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN"
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     <img
//                       src="../images/SAL.png"
//                       alt="Logo 2"
//                       style={{ width: "140px", height: "40px" }}
//                     />
//                   </a>
//                 </div>
//               </MDBRow>
//             </MDBCol>
//           </MDBRow>
//         </MDBContainer>
//       </section>

//       <div
//         className="text-center p-4 text-reset fw-bold"
//         style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
//       >
//         <div className="w-75 mx-auto">
//           <b>SC TRAVEL TIME D&R SRL</b>
//           {" | "}
//           <b>
//             Sediul Social:Adresa : Strada ANTON PANN, Nr. 2, parter, ap.3,sector
//             3, Bucuresti
//           </b>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/CUI%20TRAVEL%20TIME.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             CIF: RO17926970
//           </a>
//           {" | "} <b>Nr. Reg. Com.: J40/15226/2005</b>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/Licenta%20turism%20TRAVEL%20TIME%20D&R%20.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Licenta de turism: 236/ 04.12.2018
//           </a>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/BREVET%20Turism.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Brevet Turism
//           </a>
//           {/* {" | "}
//           <a href="">Certificat Membru Anat</a> */}
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/Polita%20insolvabilitate%20TRAVEL%20TIME%20D&R.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             {" "}
//             Polita Asigurare
//           </a>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/certificat%20ISO%202023.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             {/* Certificat ISO
//           </a>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/Politica%20de%20cookie%20Travel%20Time.pdf"
//             target="_blank"
//             rel="noreferrer"
//           > */}
//             Politica Cookies
//           </a>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/Politica%20de%20confidentialitate%20travel%20time.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Politica de Confidentialitate
//           </a>
//           {" | "}
//           <a
//             href="https://www.travel-time.ro/images/documente/Draft%20Contract%20Turist.pdf"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Draft - Contract cu Turistul
//           </a>
//           {" | "}
//           <a href="https://anpc.ro/" target="_blank" rel="noreferrer">
//             Autoritatea Nationala pentru Protectia Consumatorilor
//           </a>
//           {" | "}
//           <a
//             href="https://legislatie.just.ro/Public/DetaliiDocument/257649?fs=e&s=cl"
//             target="_blank"
//             rel="noreferrer"
//           >
//             Masurile obligatorii ANPC
//           </a>
//           {" | "}
//           <Link to={"/termenisiconditii"} onClick={() => scrollToTop()}>
//             Termeni si Conditii
//           </Link>
//         </div>
//         <p className="pt-5">© 2025 Copyright. Travel Time D&R</p>
//       </div>
//     </MDBFooter>
//   );
// };

// export default Footer;

import React from "react";
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  useColorModeValue,
  Heading,
  VStack,
  Icon,
  Flex,
  Divider,
  Wrap,
  WrapItem,
  Image,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaInstagram,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaExternalLinkAlt,
} from "react-icons/fa";
import NTPLogo from "ntp-logo-react";

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight="500"
      fontSize="lg"
      mb={2}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      {children}
    </Text>
  );
};

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Divider />
      <Box
        bg={useColorModeValue("gray.50", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
      >
        <Container as={Stack} maxW={"7xl"} py={10}>
          <SimpleGrid
            templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 1fr" }}
            spacing={8}
          >
            {/* Company Info */}
            <Stack spacing={6}>
              <Heading
                fontSize={"2xl"}
                bgGradient="linear(to-r, black, red.500)"
                bgClip="text"
              >
                TRAVEL TIME
              </Heading>
              <Text fontSize={"md"} maxW={"400px"}>
                Travel Time – o companie de top cu soluții de management al
                călătoriilor corporate și B2B – și-a construit reputația oferind
                servicii de excelență cu consecvență.
              </Text>
              <HStack spacing={4}>
                <IconButton
                  aria-label="Facebook"
                  icon={<FaFacebook />}
                  size="lg"
                  color={"white"}
                  bg={"facebook.400"}
                  _hover={{ bg: "facebook.500" }}
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/traveltimeromania",
                      "_blank"
                    )
                  }
                />
                <IconButton
                  aria-label="Instagram"
                  icon={<FaInstagram />}
                  size="lg"
                  color={"white"}
                  bg={"pink.400"}
                  _hover={{ bg: "pink.500" }}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/traveltime.ro/",
                      "_blank"
                    )
                  }
                />
              </HStack>
            </Stack>

            {/* Contact Info */}
            <Stack align={"flex-start"}>
              <ListHeader>Contact</ListHeader>
              <HStack>
                <Icon as={FaMapMarkerAlt} color="teal.400" />
                <Text fontSize="md">
                  Strada Mircea Vulcanescu, nr 45, Sector 1, Bucuresti
                </Text>
              </HStack>
              <HStack>
                <Icon as={FaEnvelope} color="teal.400" />
                <Link href="mailto:office@travel-time.ro" fontSize="md">
                  office@travel-time.ro
                </Link>
              </HStack>
              <HStack>
                <Icon as={FaPhone} color="teal.400" />
                <Link href="tel:0371121272" fontSize="md">
                  0371 12 12 72
                </Link>
              </HStack>
            </Stack>

            {/* Useful Links */}
            <Stack align={"flex-start"}>
              <ListHeader>Link-uri Utile</ListHeader>
              <Link href="/agentii-partenere" onClick={scrollToTop}>
                Agentii Partenere
              </Link>
              <Link href="/servicii" onClick={scrollToTop}>
                Servicii
              </Link>
              <Link href="/consultanta" onClick={scrollToTop}>
                Consultanta
              </Link>
              <Link href="/contact" onClick={scrollToTop}>
                Contact
              </Link>
              <Link href="/termenisiconditii" onClick={scrollToTop}>
                Termeni si Conditii
              </Link>
            </Stack>

            {/* Company Details */}
            <Stack align={"flex-start"}>
              <ListHeader>Date de Identificare</ListHeader>
              <Text fontSize="sm">SC TRAVEL TIME D&R SRL</Text>
              <Text fontSize="sm">CIF: RO17926970</Text>
              <Text fontSize="sm">Nr. Reg. Com.: J40/15226/2005</Text>

              {/* Logos Section */}
              <VStack spacing={4} align="start" mt={4}>
                {/* First row of logos */}
                <HStack spacing={4}>
                  <Image src="../images/iata.png" alt="IATA" h="35px" />
                  <Image src="../images/anat.png" alt="ANAT" h="35px" />
                  <Image
                    src="../images/logo-travelnation.png"
                    alt="tRAVEL NATION"
                    h="35px"
                  />
                </HStack>

                {/* NTP Logo */}
                <Box w="full">
                  <NTPLogo
                    color="#74deec"
                    version="orizontal"
                    secret="136590"
                  />
                </Box>

                {/* SOL and SAL logos */}
                <Flex justify="center" gap={4} w="full" flexWrap="wrap">
                  <Link
                    href="https://anpc.ro/ce-este-sal/"
                    isExternal
                    _hover={{ opacity: 0.8 }}
                  >
                    <Image
                      src="../images/SOL.png"
                      alt="SOL Logo"
                      w="140px"
                      h="35px"
                    />
                  </Link>
                  <Link
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=EN"
                    isExternal
                    _hover={{ opacity: 0.8 }}
                  >
                    <Image
                      src="../images/SAL.png"
                      alt="SAL Logo"
                      w="140px"
                      h="40px"
                    />
                  </Link>
                </Flex>
              </VStack>
            </Stack>
          </SimpleGrid>
        </Container>

        <Divider />

        {/* Bottom Bar */}
        <Box py={6} bg={useColorModeValue("gray.100", "gray.800")}>
          <Container maxW={"7xl"}>
            <VStack spacing={4}>
              {/* Documents Links */}
              <Wrap justify="center" spacing={3} px={4}>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/Licenta%20turism%20TRAVEL%20TIME%20D&R%20.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Licenta de turism: 236/ 04.12.2018
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/iata%20certficat%202025.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Certificare IATA
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/BREVET%20Turism.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Brevet Turism
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/Polita%20insolvabilitate%20TRAVEL%20TIME%20D&R.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Polita Asigurare
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/certificat%20ISO%209001.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Certificat ISO
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
              </Wrap>

              {/* Second Row of Links */}
              <Wrap justify="center" spacing={3} px={4}>
                <WrapItem>
                  <Link
                    href="https://www.travel-time.ro/images/documente/Draft%20Contract%20Turist.pdf"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Contract cu Turistul
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="https://anpc.ro/"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    ANPC
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="https://legislatie.just.ro/Public/DetaliiDocument/257649"
                    isExternal
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Masurile obligatorii ANPC
                    <Icon as={FaExternalLinkAlt} ml={1} boxSize={3} />
                  </Link>
                </WrapItem>
                <WrapItem>•</WrapItem>
                <WrapItem>
                  <Link
                    href="/termenisiconditii"
                    onClick={scrollToTop}
                    color={useColorModeValue("blue.600", "blue.200")}
                    fontSize="sm"
                    _hover={{ textDecoration: "none", color: "blue.400" }}
                  >
                    Termeni si Conditii
                  </Link>
                </WrapItem>
              </Wrap>

              {/* Copyright */}
              <Text
                pt={4}
                fontSize="sm"
                textAlign="center"
                color={useColorModeValue("gray.600", "gray.400")}
              >
                © {new Date().getFullYear()} Travel Time D&R. Toate drepturile
                rezervate.
              </Text>
            </VStack>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
