// import React from "react";
// import { Link } from "react-router-dom";
// import { Button } from "@chakra-ui/react";
// import "./b2b-header.css";

// const B2bHeader = () => {
//   return (
//     <header>
//       <div
//         className="p-5 text-center bg-image mb-3"
//         style={{
//           backgroundImage:
//             "url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')",
//           height: "400px",
//         }}
//       >
//         <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
//           <div className="d-flex justify-content-center align-items-center h-100">
//             <div className="text-white">
//               <h1 className="mb-3 title-heading" style={{ fontSize: "34px" }}>
//                 BOOKING TIME
//               </h1>
//               <Link to="/contact">
//                 <Button
//                   as="a"
//                   variant="outline"
//                   target="_blank"
//                   size="lg"
//                   colorScheme="teal"
//                   _hover={{ color: "white" }}
//                 >
//                   Incearca acum!
//                 </Button>
//               </Link>

//               <Button
//                 href="https://tbs.flytime.ro/reseller/auth/"
//                 colorScheme="teal"
//                 as="a"
//                 size="lg"
//                 variant="outline"
//                 marginLeft="10px"
//                 _hover={{ color: "white" }}
//               >
//                 Login
//               </Button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default B2bHeader;

import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  keyframes,
  IconButton,
} from "@chakra-ui/react";
import { ArrowRight, LogIn } from "lucide-react";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const B2bHeader = () => {
  const overlayBg = useColorModeValue(
    "rgba(0, 0, 0, 0.5)",
    "rgba(0, 0, 0, 0.7)"
  );

  const buttonHoverBg = useColorModeValue("white", "gray.700");
  const buttonHoverColor = useColorModeValue("blue.600", "white");

  return (
    <Box
      as="header"
      position="relative"
      height="500px"
      width="100%"
      overflow="hidden"
    >
      {/* Background Image */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage="url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        filter="brightness(0.9)"
        transition="transform 0.3s ease-in-out"
        _after={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bg: overlayBg,
          zIndex: 1,
        }}
      />

      {/* Content */}
      <Container maxW="7xl" height="100%" position="relative" zIndex={2}>
        <Stack
          height="100%"
          spacing={6}
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          animation={`${fadeIn} 1s ease-out`}
        >
          <Heading
            as="h1"
            fontSize={{ base: "3xl", md: "5xl" }}
            fontWeight="bold"
            color="white"
            letterSpacing="wide"
            textShadow="2px 2px 4px rgba(0,0,0,0.3)"
            mb={4}
            fontFamily="Montserat"
          >
            BOOKING TIME
          </Heading>

          <Text
            fontSize={{ base: "lg", md: "xl" }}
            color="gray.100"
            maxW="2xl"
            mb={6}
            fontFamily="Montserat"
          >
            Platformă B2B pentru gestionarea eficientă a rezervărilor și
            serviciilor turistice
          </Text>

          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={4}
            align="center"
          >
            <Button
              as={Link}
              to="/contact"
              size="lg"
              px={8}
              color="white"
              bg="transparent"
              border="2px solid"
              borderColor="white"
              fontFamily="Montserat"
              _hover={{
                bg: buttonHoverBg,
                color: buttonHoverColor,
                transform: "translateY(-2px)",
              }}
              _active={{
                transform: "translateY(0)",
              }}
              rightIcon={<ArrowRight size={20} />}
              transition="all 0.2s"
            >
              Încearcă acum!
            </Button>

            <Button
              as="a"
              href="https://tbs.flytime.ro/reseller/auth/"
              target="_blank"
              size="lg"
              px={8}
              color="white"
              bg="transparent"
              border="2px solid"
              borderColor="white"
              fontFamily="Montserat"
              _hover={{
                bg: buttonHoverBg,
                color: buttonHoverColor,
                transform: "translateY(-2px)",
              }}
              _active={{
                transform: "translateY(0)",
              }}
              rightIcon={<LogIn size={20} />}
              transition="all 0.2s"
            >
              Login
            </Button>
          </Stack>
        </Stack>
      </Container>

      {/* Optional: Scroll Indicator */}
      <Box
        position="absolute"
        bottom={4}
        left="50%"
        transform="translateX(-50%)"
        zIndex={2}
      >
        <Box
          width="3px"
          height="50px"
          bg="white"
          opacity={0.6}
          animation="scrollIndicator 2s infinite"
          sx={{
            "@keyframes scrollIndicator": {
              "0%": { transform: "scaleY(0)", opacity: 0.6 },
              "50%": { transform: "scaleY(1)", opacity: 0.2 },
              "100%": { transform: "scaleY(0)", opacity: 0.6 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default B2bHeader;
