// import { Flex, Box } from "@chakra-ui/react";
// import {
//   Grid,
//   GridItem,
//   Image,
//   Button,
//   Heading,
//   Center,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// export const GridPromoKlNov = () => {
//   const columns = useBreakpointValue({ base: 1, md: 2 });

//   const heightGrid = useBreakpointValue({
//     base: "250px",
//     lg: "400px",
//   });

//   const widthGrid = useBreakpointValue({
//     base: "95%",
//     lg: "95%",
//   });

//   const widthResponsiveHeading = useBreakpointValue({
//     base: "100%",
//     md: "75%",
//     lg: "50%",
//   });

//   const textResponsive = useBreakpointValue({
//     base: "20px",
//     lg: "35px",
//   });

//   const contentKLMBusiness = [
//     {
//       title: "CHICAGO(CHI)",
//       price: "Începând de la 2550 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLPremium/Chicago.jpg",
//     },
//     {
//       title: "TORONTO(YTO)",
//       price: "Începând de la 2550 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLPremium/Toronto.jpg",
//     },
//     {
//       title: "CANCUN(CUN)",
//       price: "Începând de la 2730 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLPremium/Cancun.jpg",
//     },
//     {
//       title: "LIMA(LIM)",
//       price: "Începând de la 2905 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLPremium/Lima.jpg",
//     },
//     {
//       title: "HONG KONG(HKG)",
//       price: "Începând de la 2115 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLPremium/Hong Kong.jpg",
//     },
//     {
//       title: "SAINT MARTIN(SXM)",
//       price: "Începând de la 2780 euro",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/KLPremium/Saint Marteen.jpg",
//     },
//   ];

//   const contentKLMEconomic = [
//     {
//       title: "AMSTERDAM(AMS)",
//       price: "Începând de la 185 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Amsterdam.jpg",
//     },
//     {
//       title: "NEW YORK (NYC)",
//       price: "Începând de la 530 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/New York.jpg",
//     },
//     {
//       title: "LOS ANGELES(LAX)",
//       price: "Începând de la 730 euro",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/KLEconomic/Los Angeles.jpg",
//     },
//     {
//       title: "TAMPA(TPA)",
//       price: "Începând de la 630 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Tampa.jpg",
//     },
//     {
//       title: "ARUBA(AUA)",
//       price: "Începând de la 940 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Aruba.jpg",
//     },
//     {
//       title: "CARTAGENA(CTG)",
//       price: "Începând de la 933 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Cartagena.jpg",
//     },
//     {
//       title: "HONG KONG(HKG)",
//       price: "Începând de la 600 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Hong Kong.jpg",
//     },
//     {
//       title: "TORONTO(YTO)",
//       price: "Începând de la 580 euro",
//       thumbnail: "https://travel-time.ro/images/af-kl/KLEconomic/Toronto.jpg",
//     },
//   ];

//   return (
//     <>
//       <Heading
//         width={widthResponsiveHeading}
//         mx="auto"
//         color="#00d4ff"
//         mt="7px"
//         textAlign="center"
//         size="lg"
//         as="h1"
//         mb={4}
//       >
//         <Image
//           src="/images/af-kl/KLM-logo.png"
//           alt="images"
//           w="500px"
//           mx="auto"
//           objectFit="cover"
//         />
//       </Heading>

//       <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#00a1de"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         KLM Economy Class
//       </Center>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentKLMEconomic.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#00a1de"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       {image.price}
//                     </Button>
//                   </a>

//                   {/* <a href="https://www.klm.ro/travel-guide/destinations">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       Călătorește acum cu KLM!
//                     </Button>
//                   </a> */}
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>

//       <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#00a1de"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         KLM Business Class
//       </Center>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentKLMBusiness.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#00a1de"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       {image.price}
//                     </Button>
//                   </a>
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>
//     </>
//   );
// };

// import { Flex, Box } from "@chakra-ui/react";
// import {
//   Grid,
//   GridItem,
//   Image,
//   Button,
//   Center,
//   Heading,
//   useBreakpointValue,
// } from "@chakra-ui/react";

// export const GridPromoAfNov = () => {
//   const columns = useBreakpointValue({ base: 1, md: 2 });

//   const heightGrid = useBreakpointValue({
//     base: "250px",
//     lg: "400px",
//   });

//   const widthGrid = useBreakpointValue({
//     base: "95%",
//     lg: "95%",
//   });

//   const widthResponsiveHeading = useBreakpointValue({
//     base: "100%",
//     md: "75%",
//     lg: "50%",
//   });

//   const textResponsive = useBreakpointValue({
//     base: "20px",
//     lg: "35px",
//   });

//   const contentAiFranceEconomy = [
//     {
//       title: "PARIS(CDG)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Paris.jpg",
//     },
//     {
//       title: "HELSINKI(HEL)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Helsinki.jpg",
//     },
//     {
//       title: "MADRID(MAD)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Madrid.jpg",
//     },
//     {
//       title: "BORDEAUX(BOD)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Bordeaux.jpg",
//     },
//     {
//       title: "TOULOUSE(TLS)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Toulouse.jpg",
//     },
//     {
//       title: "PORTO(OPO)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Porto.jpg",
//     },
//     {
//       title: "MARSEILLE(MRS)",
//       price: "Verifică acum!",
//       thumbnail:
//         "https://travel-time.ro/images/af-kl/af-feb-2025/Marseille.jpg",
//     },
//     {
//       title: "NICE(NCE)",
//       price: "Verifică acum!",
//       thumbnail: "https://travel-time.ro/images/af-kl/af-feb-2025/Nice.jpg",
//     },
//   ];

//   return (
//     <>
//       <Heading
//         width={widthResponsiveHeading}
//         mx="auto"
//         color="#00d4ff"
//         mt="7px"
//         textAlign="center"
//         size="lg"
//         as="h1"
//         mb={4}
//       >
//         <Image
//           src="/images/af-kl/Air France-logo.png"
//           alt="images"
//           w="500px"
//           mx="auto"
//           objectFit="cover"
//         />
//       </Heading>

//       <Center
//         className="centerText"
//         borderBottom="1px solid"
//         fontSize={textResponsive}
//         fontWeight="bold"
//         color="#0a1373"
//         textShadow="2px 2px 4px rgba(0, 0, 0)"
//         px="6"
//         mb="6"
//       >
//         Air France Economy Class
//       </Center>

//       <Flex mt="10px" flexWrap="wrap" justifyContent="center">
//         <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
//           {contentAiFranceEconomy.map((image) => {
//             return (
//               <Box key={image.id} mb={6}>
//                 <GridItem position="relative" width={widthGrid} mx="auto">
//                   <Box
//                     className="description-title"
//                     mx="auto"
//                     p={2}
//                     mb={3}
//                     boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
//                     display="flex"
//                     justifyContent="center"
//                     background="#0a1373"
//                     color="white"
//                     fontWeight="bold"
//                     fontSize="1.2rem"
//                   >
//                     {image.title}
//                   </Box>
//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Image
//                       src={image.thumbnail}
//                       alt={image.alt}
//                       w="100%"
//                       h={heightGrid}
//                       objectFit="cover"
//                     />
//                   </a>

//                   <a href="https://tbs.flytime.ro/reseller/auth/">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       {image.price}
//                     </Button>
//                   </a>
//                   {/*
//                   <a href="https://wwws.airfrance.ro/travel-guide/destinations">
//                     <Button
//                       position="absolute"
//                       bottom={0}
//                       left="50%"
//                       transform="translateX(-50%)"
//                       size="sm"
//                       background="white"
//                       // colorScheme="black"
//                       color="black"
//                       p={3}
//                       mb={3}
//                       opacity="0.9"
//                       mr={3}
//                       fontSize="1.2rem"
//                       type="button"
//                       border="1px"
//                       // _hover={{ bg: "white", color: "black" }}
//                     >
//                       Călătorește acum cu Air France!
//                     </Button>
//                   </a> */}
//                 </GridItem>
//               </Box>
//             );
//           })}
//         </Grid>
//       </Flex>
//     </>
//   );
// };

import React, { useState } from "react";
import {
  Box,
  Grid,
  Image,
  Button,
  Heading,
  VStack,
  Container,
  Text,
  Flex,
  useBreakpointValue,
  keyframes,
  ScaleFade,
} from "@chakra-ui/react";

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px #00a3ff; }
  50% { box-shadow: 0 0 20px #00a3ff; }
  100% { box-shadow: 0 0 10px #00a3ff; }
`;

export const GridPromoKlNov = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const columns = useBreakpointValue({ base: 1, md: 2, lg: 4 });
  const imageHeight = useBreakpointValue({ base: "250px", lg: "400px" });
  const headingSize = useBreakpointValue({ base: "2xl", lg: "3xl" });

  const destinations = [
    {
      title: "AMSTERDAM(AMS)",
      price: "Verifică acum!",
      thumbnail:
        "https://travel-time.ro/images/af-kl/kl-feb-2025/Amsterdam.jpg",
    },
    {
      title: "HELSINKI(HEL)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Helsinki.jpg",
    },
    {
      title: "MADRID(MAD)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Madrid.jpeg",
    },
    {
      title: "BORDEAUX(BOD)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Bordeaux.jpg",
    },
    {
      title: "TOULOUSE(TLS)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Toulouse.jpg",
    },
    {
      title: "PORTO(OPO)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Porto.jpg",
    },
    {
      title: "NANTES(NTE)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Nantes.jpg",
    },
    {
      title: "DUBLIN(DUB)",
      price: "Verifică acum!",
      thumbnail: "https://travel-time.ro/images/af-kl/kl-feb-2025/Dublin.jpg",
    },
  ];

  return (
    <Box bgGradient="linear(to-br, blue.500, black)" minH="100vh" py={8} px={4}>
      <Container maxW="container.xl">
        {/* Header Section */}
        <VStack spacing={6} mb={12}>
          <Box
            bg="whiteAlpha.100"
            // backdropFilter="blur(10px)"
            p={4}
            rounded="xl"
            w={{ base: "full", md: "container.sm" }}
          >
            <Image
              src="/images/af-kl/KLM-logo.png"
              alt="Air France Logo"
              mx="auto"
              h="70px"
              objectFit="contain"
            />
          </Box>

          <Box
            h="2px"
            w="250px"
            bgGradient="linear(to-r, blue.200, cyan.300)"
            rounded="full"
          />
        </VStack>

        {/* Grid Section */}
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={6}>
          {destinations.map((destination, index) => (
            <Box
              key={destination.title}
              position="relative"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.02)" }}
            >
              <Box
                bg="whiteAlpha.100"
                // backdropFilter="blur(8px)"
                rounded="xl"
                overflow="hidden"
                position="relative"
                animation={`${glowAnimation} 3s infinite`}
              >
                {/* Title */}
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  p={4}
                  bgGradient="linear(to-b, blackAlpha.800, transparent)"
                  zIndex={2}
                >
                  <Text fontSize="xl" fontWeight="bold" color="white">
                    {destination.title}
                  </Text>
                </Box>

                {/* Image */}
                <Box h={imageHeight} overflow="hidden">
                  <Image
                    src={destination.thumbnail}
                    alt={destination.title}
                    w="full"
                    h="full"
                    objectFit="cover"
                    transition="transform 0.5s"
                    _hover={{ transform: "scale(1.1)" }}
                  />
                </Box>

                {/* Price Button */}

                <Flex
                  position="absolute"
                  bottom={4}
                  left={0}
                  right={0}
                  justify="center"
                >
                  <ScaleFade in={hoveredIndex === index}>
                    <a href="https://tbs.flytime.ro/reseller/auth/">
                      <Button
                        bg="whiteAlpha.900"
                        color="blue.900"
                        _hover={{
                          bg: "blue.500",
                          color: "white",
                        }}
                        rounded="full"
                        px={6}
                        fontWeight="bold"
                        transition="all 0.3s"
                        // backdropFilter="blur(4px)"
                      >
                        {destination.price}
                      </Button>
                    </a>
                  </ScaleFade>
                </Flex>
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default GridPromoKlNov;
