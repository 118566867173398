import React, { useRef } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  VStack,
  HStack,
  Grid,
  GridItem,
  Center,
  List,
  ListItem,
  ListIcon,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Plane,
  PiggyBank,
  Globe,
  UserCheck,
  FileText,
  Users,
  Mail,
  CreditCard,
  Palette,
  HeartHandshake,
  UserPlus,
  Calendar,
  ClipboardCheck,
  BadgeCheck,
  BarChart,
  MessageCircle,
  CheckCircle2,
  LineChart,
  Cpu,
  Shield,
  Microchip,
  HandCoins,
  CalendarPlus,
  ThumbsUp,
} from "lucide-react";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(40px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ServicesPage = () => {
  const businessTravelRef = useRef(null);
  const eventsRef = useRef(null);
  const consultancyRef = useRef(null);

  const scrollToSection = (ref) => {
    const targetPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // Increased duration to 2 seconds
    let start = null;

    const ease = (t) => {
      // easeInOutCubic function for smooth acceleration and deceleration
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const animation = (currentTime) => {
      if (start === null) start = currentTime;
      const timeElapsed = currentTime - start;
      const progress = Math.min(timeElapsed / duration, 1);

      window.scrollTo(0, startPosition + distance * ease(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const headingSize = useBreakpointValue({ base: "2xl", md: "4xl", lg: "6xl" });
  const subHeadingSize = useBreakpointValue({
    base: "xl",
    md: "2xl",
    lg: "3xl",
  });
  const buttonDirection = useBreakpointValue({ base: "column", lg: "row" });

  const buttonStyles = {
    size: buttonSize,
    colorScheme: "blue",
    px: 8,
    py: 6,
    fontSize: { base: "md", md: "xl" },
    whiteSpace: "normal",
    height: "auto",
    bgColor: "rgba(66, 153, 225, 0.5)", // blue.500 with 0.5 opacity
    backdropFilter: "blur(8px)",
    border: "2px solid",
    borderColor: "whiteAlpha.400",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    transition: "all 0.3s ease",
    _hover: {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 40px rgba(0, 0, 0, 0.4)",
      bgColor: "rgba(66, 153, 225, 0.7)",
      borderColor: "whiteAlpha.600",
    },
    _active: {
      transform: "translateY(1px)",
      boxShadow: "0 2px 20px rgba(0, 0, 0, 0.2)",
    },
  };

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const categories = [
    {
      title: "Website-uri personalizate pentru evenimente",
      icon: Globe,
      items: [
        {
          text: "Gestionarea înregistrărilor",
          icon: UserCheck,
          description:
            "Simplifică înscrierile participanților cu instrumente intuitive de înregistrare.",
        },
        {
          text: "Plăți securizate",
          icon: CreditCard,
          description:
            "Asigură tranzacții rapide, sigure și de încredere pentru toți participanții.",
        },
        {
          text: "Formulare de înregistrare personalizate",
          icon: FileText,
          description:
            "Colectează date precise prin formulare adaptate nevoilor specifice ale evenimentului tău.",
        },
        {
          text: "Distribuirea agendei",
          icon: Calendar,
          description:
            "Transmite agenda evenimentului cu ușurință participanților, asigurând informarea completă a acestora.",
        },
        {
          text: "Design adaptabil personalizat",
          icon: Palette,
          description:
            "Creează website-uri atractive și complet adaptabile, adaptate brandului tău.",
        },
      ],
    },
    {
      title: "Experiența participanților la evenimente",
      icon: Users,
      items: [
        {
          text: "Instrumente de marketing",
          icon: Mail,
          description:
            "Crește participarea și interacțiunea cu ajutorul campaniilor de marketing integrate.",
        },
        {
          text: "Gestionarea sponsorilor",
          icon: HeartHandshake,
          description:
            "Facilitează parteneriatele printr-o coordonare eficientă a sponsorilor.",
        },
        {
          text: "Managementul participanților",
          icon: UserPlus,
          description:
            "Monitorizează înregistrările, preferințele și prezența participanților cu ușurință.",
        },
        {
          text: "Primirea participanților și asistență",
          icon: ClipboardCheck,
          description:
            "Asigură check-in-uri rapide și o atmosferă primitoare cu suport dedicat la locație.",
        },
        {
          text: "Înregistrare și urmărire la fața locului",
          icon: BadgeCheck,
          description:
            "Gestionează înscrierile pe loc și urmărește în timp real activitatea participanților.",
        },
      ],
    },
    {
      title: "Informații și analize detaliate",
      icon: BarChart,
      items: [
        {
          text: "Chestionare",
          icon: MessageCircle,
          description:
            "Colectează feedback și informații în timp real prin sondaje interactive.",
        },
        {
          text: "Sondaje de satisfacție",
          icon: CheckCircle2,
          description:
            "Măsoară instant nivelul de satisfacție al participanților pentru a identifica zonele care necesită îmbunătățiri.",
        },
        {
          text: "Controlul costurilor",
          icon: CreditCard,
          description:
            "Obține vizibilitate asupra cheltuielilor cu ajutorul rapoartelor detaliate și al instrumentelor de analiză.",
        },
        {
          text: "Email marketing",
          icon: Mail,
          description:
            "Menține legătura cu audiența înainte, în timpul și după eveniment.",
        },
        {
          text: "Monitorizarea indicatorilor",
          icon: LineChart,
          description:
            "Urmărește și analizează indicatorii de performanță cheie pentru a evalua succesul evenimentului.",
        },
      ],
    },
  ];

  return (
    <Box minH="100vh">
      {/* Hero Section */}
      <Box
        pos="relative"
        h={{ base: "400px", md: "600px" }}
        backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2255777013.jpg')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        {/* Dark Overlay */}
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="blackAlpha.100"
        />

        {/* Content */}
        <Flex
          pos="relative"
          direction="column"
          align="center"
          justify="center"
          h="100%"
          color="white"
          textAlign="center"
          px={4}
          animation={`${fadeIn} 1s ease-out`}
        >
          <Heading
            as="h1"
            color="white"
            fontSize={["4xl", "5xl", "8xl"]}
            textAlign="center"
            mb={4}
            fontFamily="'Montserrat', sans-serif"
          >
            Servicii
          </Heading>

          <Flex
            direction={buttonDirection}
            gap={{ base: 4, md: 6 }}
            w={{ base: "full", md: "auto" }}
            maxW="container.lg"
            justify="center"
            fontFamily="'Montserrat', sans-serif"
          >
            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(businessTravelRef)}
              minW={{ lg: "400px" }}
            >
              Managementul călătoriilor de afaceri
            </Button>

            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(eventsRef)}
              minW={{ lg: "400px" }}
            >
              Managementul Evenimentelor
            </Button>

            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(consultancyRef)}
              minW={{ lg: "400px" }}
            >
              Consultanță
            </Button>
          </Flex>
        </Flex>
      </Box>
      {/* Business Travel Section */}
      <Box ref={businessTravelRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2137645247.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Managementul călătoriilor de afaceri
              </Heading>
            </Center>
          </Box>
        </Box>

        <Container maxW="container.xl" py={{ base: 12, md: 20 }}>
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
            gap={{ base: 8, md: 12 }}
          >
            {/* Card 1 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Plane size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Soluții complete de călătorie
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  La Travel Time, mergem dincolo de simpla organizare a
                  călătoriilor de afaceri – oferim servicii de excelență
                  asigurându-ne că fiecare călătorie este fluidă, sigură și
                  lipsită de stres. Combinând accesul la conținut relevant,
                  tehnologie, platforme avansate de rezervare și rapoarte
                  detaliate, am devenit un partener de încredere pentru
                  companiile care adoptă soluții inteligente de management al
                  călătoriilor.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  De la organizarea zborurilor, hotelurilor și serviciilor
                  feroviare până la gestionarea transportului terestru, a
                  pașapoartelor și vizelor, ne ocupăm de fiecare detaliu cu o
                  precizie impecabilă, folosind o echipă dedicată fiecărui
                  client. Cu o bază solidă de expertiză, cunoștințe aprofundate
                  ale pieței și o atenție centrată pe client, serviciile noastre
                  redefinesc standardele pentru un management eficient al
                  călătoriilor corporate.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 2 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <PiggyBank size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Maximizarea economiilor
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Travel Time adoptă o abordare strategică pentru optimizarea
                  costurilor, ajutând clienții să obțină economii semnificative
                  fără a compromite calitatea. Prin valorificarea expertizei
                  noastre, a volumelor consolidate și a accesului la conținut
                  relevant pe piață, oferim tarife și prețuri competitive,
                  permițând companiilor să reducă costurile de călătorie și, în
                  același timp, să îmbunătățească satisfacția călătorilor.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Echipele noastre dedicate de management al conturilor
                  analizează tiparele de cheltuieli, optimizează strategiile de
                  prețuri și creează programe personalizate pentru nevoile
                  specifice ale fiecărui client. Travel Time utilizează
                  tehnologii dezvoltate intern și roboți Amadeus pentru a
                  eficientiza operațiunile, a reverifica tarifele și a genera
                  economii.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 3 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Cpu size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Eficiență bazată pe tehnologie
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Cu ajutorul tehnologiei de ultimă generație, Travel Time oferă
                  companiilor posibilitatea de a monitoriza cheltuielile în timp
                  real, de a analiza comportamentul călătorilor și de a
                  identifica tendințele de neconformitate. Soluțiile noastre
                  includ mai multe OBT-uri (Online Booking Tools), rapoarte
                  personalizate, analize avansate de date și strategii adaptate
                  pentru creșterea conformității și optimizarea operațiunilor.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Infrastructura noastră tehnologică solidă, alimentată de
                  robotică și sisteme bazate pe inteligență artificială,
                  integrează perfect soluții locale și regionale. Combinând
                  managementul proactiv al conturilor cu instrumente flexibile,
                  adaptate cerințelor unice ale organizației tale, oferim
                  eficiență și inovație în managementul călătoriilor.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 4 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Shield size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Suport complet 24/7 pentru situații de urgență
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  La Travel Time, siguranța și satisfacția călătorilor sunt în
                  centrul tuturor activităților noastre. Soluțiile noastre
                  dedicate pentru duty of care utilizează instrumente avansate
                  pentru a localiza călătorii în timp real, a gestiona proactiv
                  perturbările și a oferi răspunsuri rapide în situații de
                  urgență.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Cu servicii robuste de suport 24/7, planuri de contingență
                  bine documentate și proceduri eficiente de gestionare a
                  reclamațiilor, Travel Time pune la dispoziție unul dintre cele
                  mai fiabile și receptive sisteme de suport live din industrie,
                  oferind liniște și siguranță fiecărui călător.
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      {/* Events Management Section */}
      <Box ref={eventsRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2485923623.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Managementul Evenimentelor
              </Heading>
            </Center>
          </Box>
        </Box>

        <Container maxW="container.xl" py={{ base: 12, md: 20 }}>
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
            gap={{ base: 8, md: 12 }}
          >
            {/* Card 1 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Microchip size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Procesul
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Un Manager de Proiect dedicat supraveghează întregul proces,
                  de la selecția locației până la planificarea detaliată a
                  evenimentului și controlul bugetului, asigurând rezultate
                  remarcabile. Cu o experiență dovedită în organizarea de
                  evenimente pentru grupuri de la 6 până la 300 de participanți
                  – incluzând întâlniri restrânse, lansări de produse,
                  teambuilding-uri și soluții de incentivare – Travel Time oferă
                  expertiză completă în planificarea, implementarea, raportarea
                  și gestionarea întregului spectru de evenimente corporate.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 2 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <CalendarPlus size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Planificare detaliată
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Serviciile noastre acoperă fiecare aspect al logisticii
                  evenimentelor, consolidând și gestionând toate etapele legate
                  de călătorii și organizare cu transparență și opțiuni
                  multiple. Această abordare generează economii semnificative
                  pentru programele MICE, oferind în același timp valoare
                  excepțională și eficiență maximă a costurilor.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 3 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <HandCoins size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Bugetul
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Recomandăm doar locații de încredere și asigurăm transparență
                  totală asupra bugetului în fiecare etapă a procesului. Analiza
                  datelor, raportarea și tendințele pieței permit Travel Time să
                  echilibreze costurile și beneficiile în funcție de obiectivele
                  clienților. Soluțiile noastre scalabile, combinate cu o
                  abordare creativă și consultativă, oferă clienților control
                  asupra costurilor, menținând în același timp standardele de
                  calitate.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 4 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <ThumbsUp size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Evenimente Travel Time: Soluții complete pentru experiențe
                  fără cusur
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  La Travel Time, oferim soluții complete de management al
                  evenimentelor, care îmbină tehnologia de ultimă generație cu o
                  planificare profesională, pentru a ne asigura că fiecare
                  detaliu este executat impecabil. Serviciile noastre, bogate în
                  funcționalități, sunt concepute pentru a simplifica procesul
                  de organizare, a crește implicarea participanților și a oferi
                  informații valoroase pentru măsurarea performanței și
                  evaluarea succesului.
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Box py={{ base: 12, md: 20 }} bg={bgColor}>
        <Container maxW="container.xl">
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
            gap={{ base: 8, lg: 6 }}
          >
            {categories.map((category, idx) => (
              <GridItem
                key={idx}
                bg={cardBg}
                p={8}
                borderRadius="xl"
                borderWidth="1px"
                borderColor={borderColor}
                boxShadow="xl"
                transition="all 0.3s"
                _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
              >
                <VStack spacing={6} align="start">
                  <Box
                    w="full"
                    display="flex"
                    alignItems="center"
                    gap={3}
                    pb={4}
                    borderBottomWidth="2px"
                    borderColor="blue.500"
                  >
                    <category.icon size={28} color="#3182CE" />
                    <Heading
                      as="h3"
                      size="md"
                      color="blue.600"
                      fontFamily="Montserrat"
                    >
                      {category.title}
                    </Heading>
                  </Box>

                  <List spacing={6} w="full">
                    {category.items.map((item, itemIdx) => (
                      <ListItem
                        key={itemIdx}
                        display="flex"
                        alignItems="flex-start"
                        gap={3}
                      >
                        <ListIcon
                          as={item.icon}
                          color="blue.500"
                          mt={1}
                          boxSize={5}
                        />
                        <Box>
                          <Text
                            fontWeight="600"
                            color="gray.700"
                            fontSize="md"
                            mb={1}
                          >
                            {item.text}
                          </Text>
                          <Text
                            color="gray.600"
                            fontSize="sm"
                            lineHeight="tall"
                            fontFamily="Montserrat"
                          >
                            {item.description}
                          </Text>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* Business Travel Section */}
      <Box ref={consultancyRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_670699621.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Consultanță
              </Heading>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesPage;
