import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Flex,
  Text,
  VStack,
  Divider,
} from "@chakra-ui/react";
import { CalendarDays, Users, Building2, BookOpenCheck } from "lucide-react";

const StatBox = ({ icon: Icon, number, text }) => (
  <Box
    bg="white"
    p={10}
    borderRadius="lg"
    boxShadow="lg"
    transition="transform 0.2s"
    _hover={{ transform: "translateY(-5px)" }}
  >
    <VStack spacing={5}>
      <Flex
        w="60px"
        h="60px"
        bg="blue.50"
        borderRadius="full"
        align="center"
        justify="center"
      >
        <Icon size={30} color="#3182ce" />
      </Flex>
      <Text
        fontSize={["5xl", "4xl"]}
        fontWeight="bold"
        color="#000f40"
        fontFamily="'Montserrat', sans-serif"
        sx={{
          WebkitTextStrokeWidth: "1px",
          WebkitTextFillColor: "#fff",
          WebkitTextStrokeColor: "#3182ce",
          lineHeight: "33px",
        }}
      >
        {number}
      </Text>
      <Text
        fontSize={["md", "md"]}
        textAlign="center"
        color="gray.600"
        fontWeight="medium"
        fontFamily="'Montserrat', sans-serif"
      >
        {text}
      </Text>
    </VStack>
  </Box>
);

const StatsSection = () => {
  const stats = [
    {
      icon: CalendarDays,
      number: "20",
      text: "ani pe piața din România",
    },
    {
      icon: Users,
      number: "30+",
      text: "angajați",
    },
    {
      icon: BookOpenCheck,
      number: "12+",
      text: "ani - media de expertiză a consultanților Travel Time  ",
    },
    {
      icon: Building2,
      number: "100+",
      text: "clienți corporativi",
    },
  ];

  return (
    <>
      <Container maxW="container.lg" py={12}>
        <Box width={["100%", "100%"]} mx="auto">
          <SimpleGrid columns={[1, 2, 2, 4]} spacing={8}>
            {stats.map((stat, index) => (
              <StatBox
                key={index}
                icon={stat.icon}
                number={stat.number}
                text={stat.text}
              />
            ))}
          </SimpleGrid>
        </Box>
      </Container>
    </>
  );
};

export default StatsSection;
