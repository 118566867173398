import React from "react";
import ContentCapabilities from "../../components/components-new-consultanta/contentCapabilities";
import BenefitsSection from "../../components/components-new-consultanta/benefitsSection";
import TechSolutionsSection from "../../components/components-new-consultanta/solutionsSection";
import TeamMembers from "../../components/components-new-consultanta/teamMembers";

const Capabilities = () => {
  return (
    <>
      <ContentCapabilities />
      <BenefitsSection />
      <TechSolutionsSection />
      <TeamMembers />
    </>
  );
};

export default Capabilities;
