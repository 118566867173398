import React from "react";
import ServicesPage from "../../components/components-new-servicii/servicesPage";
import ConsultingSection from "../../components/components-new-servicii/strategicConsultancy";
import TravelPolicySection from "../../components/components-new-servicii/policyTravel";
import FutureSection from "../../components/components-new-servicii/futurePerspective";

const Services = () => {
  return (
    <>
      <ServicesPage />
      {/* <HeaderConsultancy /> */}
      <ConsultingSection />
      <TravelPolicySection />
      <FutureSection />
    </>
  );
};

export default Services;
